<!-- eslint-disable vue/no-mutating-props -->
<template>
  <section>
    <b-modal
      v-model="showModal"
      size="md"
      no-close-on-esc
      no-close-on-backdrop
      title="Update Impressions"
      class="p-4"
    >
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="submitData"
        >
          <div
            v-if="dataLoad"
            class="spinner"
          >
            <b-spinner
              small
              class="d-inline-block"
            />
            <span class="sr-only">Loading...</span>
          </div>
          Update
        </b-button>
      </template>
      <validation-observer ref="updateImpressionsForm">
        <ValidationProvider
          v-slot="{ errors }"
          name="device-impressions"
          vid="impressions"
          rules="required|numeric|min_value:5"
        >
          <b-form-group
            label-for="impressions"
            label="Update Impressions"
            :class="errors.length > 0 ? 'is-invalid' : null"
            class="mx-2"
          >
            <b-form-input
              id="impressions"
              v-model="impressionsValue"
              placeholder="Enter Impressions"
              @wheel="onWheel"
            />
          </b-form-group>
          <b-form-invalid-feedback
            class="ml-2"
            :state="errors.length > 0 ? false:null"
          >
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </ValidationProvider>
      </validation-observer>
      <b-overlay
        :show="dataLoad"
        no-wrap
      />
    </b-modal>
  </section>
</template>
<script>
import {
  BModal, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BOverlay, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric } from '@validations'
import { min_value } from 'vee-validate/dist/rules'
import AccountTypes from '@/common/enums/accountTypeEnum'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    BModal,
    BOverlay,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BSpinner,
  },
  emits: ['refresh-data'],
  data() {
    return {
      showModal: false,
      dataLoad: false,
      required,
      min_value,
      numeric,
      AccountTypes,
      impressionsValue: 0,
      device: null,
    }
  },
  computed: {
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
    deviceData() {
      return this.data
    },
  },

  methods: {
    showImpressionModal(device) {
      if (device) {
        this.impressionsValue = parseInt(device.impressions, 10)
        this.device = device
      }
      this.showModal = !this.showModal
    },
    onWheel(e) {
      e.target.blur() // This will remove focus from the input element when the wheel event occurs
    },
    async submitData() {
      try {
        const success = await this.$refs.updateImpressionsForm.validate()
        if (success) {
          this.dataLoad = true
          if (this.userRole === this.AccountTypes.ADMIN) {
            const response = await this.$store.dispatch('devices/updateDeviceImpressionAdmin', { deviceId: this.device.id, impressions: parseInt(this.impressionsValue, 10), userHasGroupId: this.device.user_has_group.id })
            if (response.data.statusCode === 200) {
              this.$emit('refresh-data')
              this.showImpressionModal(null)
              showToast('Impression Updated', response.data.message.toString(), 'success')
              this.dataLoad = false
            }
          } else {
            const response = await this.$store.dispatch('devices/updateDeviceImpressionUser', { deviceId: this.device.id, impressions: parseInt(this.impressionsValue, 10) })
            if (response.data.statusCode === 200) {
              this.$emit('refresh-data')
              this.showImpressionModal(null)
              showToast('Impression Updated', response.data.message.toString(), 'success')
              this.dataLoad = false
            }
          }
        } else {
          this.dataLoad = false
          this.$swal('Enter valid data')
        }
      } catch (e) {
        this.$swal(e.response.data.message)
      }
    },
  },
}
</script>
